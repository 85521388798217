import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Autocomplete,
    MenuItem,
    Select,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
    Card,
    CardContent,
} from "@mui/material";
import { formatDate, itemsPerPage } from '../../components/Utils';

function ListProviderRetentions() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [amounts, setAmounts] = useState({}); // To store amounts to pay for each invoice
    const [errors, setErrors] = useState({}); // To store errors for each invoice

    const [status, setStatus] = useState(null);
    const [code, setCode] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [totalRetention, setTotalRetention] = useState(null);
    const [paidRetention, setPaidRetention] = useState(null);
    const [remainingRetentionSum, setRemainingRetentionSum] = useState(null);
    const [totalRetentionOverdue, setTotalRetentionOverdue] = useState(null);

    const fetchInvoices = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;
            params.only_provider_invoices = true;
            if (code) params.code = code;
            if (status) params.retention_status = status;
            if (selectedBuilding) params.building_id = selectedBuilding.id;
            if (selectedProvider) params.client_id = selectedProvider.id;
            if (sortColumn) params.sort_by = sortColumn;
            if (sortDirection) params.sort_direction = sortDirection;

            const result = await api().get("/invoices/", { params });

            setInvoices(result.data.paginated_data);
            setTotalItems(result.data.data_length);
            setTotalRetention(result.data.total_retentions);
            setPaidRetention(result.data.paid_retention);
            setRemainingRetentionSum(result.data.remaining_retention_sum);
            setTotalRetentionOverdue(result.data.total_retention_overdue);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, code, status, selectedProvider, selectedBuilding, sortColumn, sortDirection]);

    const fetchproviders = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/providers/");

            setProviders(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.disabled = false;
            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchproviders();
        fetchBuildings();
    }, []);

    useEffect(() => {
        fetchInvoices();
    }, [currentPage, sortColumn, sortDirection]);

    useEffect(() => {
        if (fetchReset) {
            fetchInvoices();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleStatusChange = (event) => {
        const { value } = event.target;
        setStatus(value);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleApplyFilters = () => {
        setCurrentPage(1);
        fetchInvoices();
    };

    const handleResetFilters = () => {
        setCurrentPage(1);
        setStatus(null);
        setSelectedProvider(null);
        setSelectedBuilding(null);
        setCode(null);
        setFetchReset(true);
    };

    const handleClientChange = (event, newValue) => {
        setSelectedProvider(newValue);
    };

    const handleBuildingChange = (event, newValue) => {
        setSelectedBuilding(newValue);
    };

    const handleAmountChange = (id, value) => {
        const invoice = invoices.find(inv => inv.id === id);
        const remainingAmount = invoice.retention - (invoice.paid_retention ?? 0);

        let newErrors = { ...errors };

        // Validate the amount
        if (value <= 0) {
            newErrors[id] = "El monto debe ser mayor a 0.";
        } else if (value > remainingAmount) {
            newErrors[id] = `El monto no puede exceder el restante (${remainingAmount.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })}€).`;
        } else {
            delete newErrors[id];
        }

        setAmounts({
            ...amounts,
            [id]: value
        });

        setErrors(newErrors);
    };
    const handlePayRetention = async (id) => {
        try {
            const amount = amounts[id];
            if (!amount || amount <= 0) {
                alert("Por favor, introduzca un valor válido.");
                return;
            }
            const result = await api().patch('/invoices/pay_retention', { invoice_id: id, amount: amount });
            if (result.status < 300) {
                alert('Factura pagada correctamente');
                fetchInvoices();
                setError(null);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
            fetchInvoices();
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '10%'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    {/* Filters and Sorting */}
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={3} sm={2}>
                                    <TextField
                                        fullWidth
                                        label="Factura"
                                        name="code"
                                        value={code || ""}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                        value={selectedBuilding}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obra"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={providers}
                                        getOptionLabel={(option) => option.code?.toString() + '-' + option.name?.toString()}
                                        value={selectedProvider}
                                        onChange={handleClientChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Proveedor"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} sm={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-label">Estado</InputLabel>
                                        <Select
                                            labelId="status-label"
                                            id="status"
                                            name="status"
                                            value={status}
                                            onChange={handleStatusChange}
                                            label="Tipo"
                                        >
                                            <MenuItem value="paid">PAGADA</MenuItem>
                                            <MenuItem value="not_paid">NO PAGADA</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    {/* Sums Display */}
                    <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                        <Grid item xs={12} sm={3}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Importe
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {totalRetention ? totalRetention.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Pagado
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {paidRetention ? paidRetention.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Restante
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {remainingRetentionSum ? remainingRetentionSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card elevation={3} sx={{ padding: 1.5 }}>
                                <CardContent>
                                    <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                        Total Vencido
                                    </Typography>
                                    <Typography variant="h6" align="center" color="primary">
                                        {totalRetentionOverdue ? totalRetentionOverdue.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0€'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                Código Factura {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('retention_limit_date')} style={{ cursor: 'pointer' }}>
                                                Fecha vencimiento{sortColumn === 'retention_limit_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('building_id')} style={{ cursor: 'pointer' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Obra {sortColumn === 'building_id' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </Typography>
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('provider_id')} style={{ cursor: 'pointer' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Proveedor {sortColumn === 'provider_id' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </Typography>
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Estado
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('retention')} style={{ cursor: 'pointer' }}>
                                                Importe {sortColumn === 'retention' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('paid_retention')} style={{ cursor: 'pointer' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Pagado {sortColumn === 'paid_retention' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </Typography>
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('remaining_retention')} style={{ cursor: 'pointer' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    Restante{sortColumn === 'remaining_retention' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </Typography>
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={cellStyle}>
                                        <Typography variant="h6" gutterBottom>
                                            Cantidad a Pagar
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography variant="h6" gutterBottom>
                                            Acciones
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((item) => {
                                    const remainingAmount = item.retention - (item.paid_retention ?? 0);
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                <Link to={`/invoices/update/${item.id}`}>
                                                    {item.code || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {formatDate(item.retention_limit_date) || '-'}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                <Link to={`/buildings/update/${item.building.id}`}>
                                                    {item.building.code || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                <Link to={`/providers/update/${item.provider.id}`}>
                                                    {item.provider.code || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {item.retention_status === 'paid' ? 'PAGADA' :
                                                    item.retention_status === 'not_paid' ? 'NO PAGADA' :
                                                        item.retention_status || '-'}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {item.retention ? item.retention.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={cellStyle}>
                                                {item.paid_retention ? item.paid_retention.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                                            </TableCell>
                                            <TableCell sx={cellStyle}>
                                                {item.retention ?
                                                    (item.retention - (item.paid_retention ?? 0)).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                                    : '0,00€'}
                                            </TableCell>

                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={amounts[item.id] || ''}
                                                    onChange={(e) => handleAmountChange(item.id, parseFloat(e.target.value))}
                                                    fullWidth
                                                    error={!!errors[item.id]}
                                                    helperText={errors[item.id]}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handlePayRetention(item.id)}
                                                    sx={{ marginRight: '8px' }}
                                                    disabled={remainingAmount <= 0 || !!errors[item.id] || !amounts[item.id]}
                                                >
                                                    Pagar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default ListProviderRetentions;
