import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { Add as AddIcon } from '@mui/icons-material';
import {
    TextField,
    Button,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Paper,
    Box
} from '@mui/material';
import Accordion from '../../components/Accordion';

function EditWorker() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const [documents, setDocuments] = useState([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [typeDelete, setTypeDelete] = useState(false);

    const [workerForm, setWorkerForm] = useState({
        email: null,
        name: null,
        surname: null,
        documentation: null,
        doc_type: null,
        address: null,
        telephone: null,
        bank_account: null
    });

    const fetchWorker = useCallback(async () => {
        try {
            const result = await api().get(`/workers/${id}`);
            setWorkerForm(result.data);
            setWorkerForm(prevState => ({ ...prevState, user_id: id }));
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo operario");
            setLoading(false);
        }
    }, [api, id]);

    const fetchDocuments = useCallback(async () => {
        try {
            const params = { worker_id: id };
            const result = await api().get(`/documents/`, { params });
            setDocuments(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo documentos");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchWorker();
        fetchDocuments();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWorkerForm({ ...workerForm, [name]: value });
    };
    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleDeleteItem = async () => {
        try {
            let data = {}
            if (typeDelete === '/workers/disable') data.worker_id = deleteId
            if (typeDelete === '/documents/disable') data.document_id = deleteId

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/workers/disable') {
                    navigate(`/workers/list`);
                } else if (typeDelete === '/documents/disable') {
                    fetchDocuments();
                }
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        };
    };

    const validateForm = () => {
        if (workerForm.email && workerForm.email.length > 36) {
            return "El email no debe exceder los 36 caracteres";
        }
        if (workerForm.name && workerForm.name.length > 36) {
            return "El nombre no debe exceder los 36 caracteres";
        }
        if (workerForm.surname && workerForm.surname.length > 36) {
            return "Los apellidos no deben exceder los 36 caracteres";
        }
        if (workerForm.documentation && workerForm.documentation.length > 25) {
            return "La documentación no puede exceder los 25 caracteres";
        }
        if (workerForm.documentation && !workerForm.doc_type) {
            return "Si especifica una documentacion tiene que marcar tambien el tipo";
        }
        if (workerForm.address && workerForm.address.length > 50) {
            return "La dirección no debe exceder los 50 caracteres";
        }
        if (workerForm.telephone && workerForm.telephone.length > 15) {
            return "El teléfono no debe exceder los 15 caracteres";
        }
        if (workerForm.bank_account && workerForm.bank_account.length > 50) {
            return "La cuenta bancaria no debe exceder los 150 caracteres";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validationError = validateForm();
            if (validationError) {
                setError(validationError);
                return;
            }
            setLoading(true);
            const result = await api().patch(`/workers/update`, workerForm);
            if (result.status < 300) {
                alert('Operario actualizado correctamente');
                fetchWorker();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadError("Por favor selecciona un archivo");
            return;
        }
        const formData = new FormData();
        formData.append('document', selectedFile);
        formData.append('worker_id', id);

        try {
            setLoading(true);
            const result = await api().post(`/documents/add`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (result.status < 300) {
                alert('Documento subido correctamente');
                fetchDocuments();
                setUploadModalOpen(false);
                setSelectedFile(null);
                setUploadError(null);
            } else {
                setUploadError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setUploadError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setLoading(false);
        }
    };
    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };
    return (
        <div style={{ padding: 20 }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {workerForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8}>
                        <Accordion title={`${workerForm.name}`}>
                            <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={workerForm.name}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 36 }}
                                            InputLabelProps={{ shrink: !!workerForm.name }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Apellidos"
                                            name="surname"
                                            value={workerForm.surname}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 36 }}
                                            InputLabelProps={{ shrink: !!workerForm.surname }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={workerForm.email}
                                            onChange={handleChange}
                                            margin="normal"
                                            InputLabelProps={{ shrink: !!workerForm.email }}
                                            inputProps={{ maxLength: 36 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="telephone"
                                            value={workerForm.telephone}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 15 }}
                                            InputLabelProps={{ shrink: !!workerForm.telephone }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="document-type-label">
                                                Documentación
                                            </InputLabel>
                                            <Select
                                                labelId="document-type-label"
                                                id="document-type"
                                                label='Tipo documentación'
                                                value={workerForm.doc_type}
                                                onChange={handleChange}
                                                margin={'1'}
                                                name="doc_type"
                                                inputProps={{ id: 'document-type' }}
                                            >
                                                <MenuItem value="dni">DNI</MenuItem>
                                                <MenuItem value="nie_others">NIE/Otros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Documentación"
                                            name="documentation"
                                            value={workerForm.documentation}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 25 }}
                                            InputLabelProps={{ shrink: !!workerForm.documentation }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={workerForm.address}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 50 }}
                                            InputLabelProps={{ shrink: !!workerForm.address }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Cuenta bancaria"
                                            name="bank_account"
                                            value={workerForm.bank_account}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 50 }}
                                            InputLabelProps={{ shrink: !!workerForm.bank_account }}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDeleteDialog(id, '/workers/disable')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                            Actualizar
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>
                        <Accordion title="Documentos">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setUploadModalOpen(true)}
                                startIcon={<AddIcon />}
                                style={{ marginTop: 10 }}
                            >
                                Subir documento
                            </Button>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documents.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Button onClick={() => handleDownloadDocument(item.id, item.name)}>
                                                        {item.name}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        onClick={() => handleOpenDeleteDialog(item.id, '/documents/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
                                <DialogTitle>Subir documento (pdf, word, excel o imagen hasta 20mb)</DialogTitle>
                                <DialogContent>
                                    <input type="file" onChange={handleFileChange} />
                                    {uploadError && (
                                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                            {uploadError}
                                        </Typography>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setUploadModalOpen(false)} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button onClick={handleUpload} color="primary" variant='contained'>
                                        Enviar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Accordion>

                        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                            <DialogTitle>¿Estás seguro?</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="primary">
                                    Volver
                                </Button>
                                <Button onClick={handleDeleteItem} color="error">
                                    Eliminar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                </div>
            )}
        </div>
    );
}

export default EditWorker;
