import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    TextField,
    Button,
    Box,
    Modal,
    Typography,
    Grid,
    Autocomplete,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogTitle
} from '@mui/material';
import Accordion from '../../components/Accordion';
import UploadDocument from '../../components/UploadDocument';
import { formatDate, formatTime } from '../../components/Utils';

function EditProduct() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [typeDelete, setTypeDelete] = useState('');
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [allocations, setAllocations] = useState([]);
    const [documents, setDocuments] = useState([]);

    const [workers, setWorkers] = useState([]);
    const [modalError, setModalError] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [selectedWorkerForAssignment, setSelectedWorkerForAssignment] = useState(null);

    const [productForm, setProductForm] = useState({
        name: '',
        type_id: '',
    });

    const fetchProduct = useCallback(async () => {
        try {
            const result = await api().get(`/products/${id}`);
            const product = result.data;
            setProductForm(product);
            setAllocations(product.allocations);
            setDocuments(product.documents); // Actualiza los documentos
            setProductForm(prevState => ({ ...prevState, product_id: id }));
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo producto");
            setLoading(false);
        }
    }, [api, id]);
    const fetchWorkers = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.disabled = false;
            
            const result = await api().get("/workers/");

            setWorkers(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api])
    const fetchTypes = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get("/products/types");
            setTypes(result.data);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data?.detail)}`);
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        fetchTypes();
        fetchProduct();
        fetchWorkers();
    }, []);

    useEffect(() => {
        if (productForm.type_id && types.length > 0) {
            const selected = types.find(type => type.id === productForm.type_id);
            setSelectedType(selected);
        }
    }, [productForm.type_id, types]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTypeChange = (event, newValue) => {
        setSelectedType(newValue);
        setProductForm(prevState => ({
            ...prevState,
            type_id: newValue ? newValue.id : ''
        }));
    };

    const validateForm = () => {
        if (!productForm.name) {
            return "Por favor, añada un nombre";
        }
        if (!productForm.type_id) {
            return "Por favor, añada un tipo";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        try {
            const result = await api().patch(`/products/update`, productForm);
            if (result.status < 300) {
                alert('Producto actualizado correctamente');
                fetchProduct();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response?.data?.detail)}`);
        }
    };
    const handleOpenAssignModal = () => {
        setOpenAssignModal(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssignModal(false);
        setModalError(null);
        setSelectedWorkerForAssignment(null);
    };
    const handleAssignWorker = async () => {
        setModalLoading(true);
        try {
            const result = await api().post(`/allocations/start`, {
                product_id: id,
                worker_id: selectedWorkerForAssignment.id
            });

            if (result.status < 300) {
                fetchProduct();
                setModalError(null);
                handleCloseAssignModal(false);
            } else {
                setModalError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setModalError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        } finally {
            setModalLoading(false);
        }
    };
    const handleUnassignProduct = async () => {
        try {
            const result = await api().patch(`/allocations/finish`,
                { product_id: id });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchProduct();
                    handleCloseAssignModal();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }
    };

    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };
    const handleWorkerForAssignmentChange = (event, newValue) => {
        setModalError(null);
        setSelectedWorkerForAssignment(newValue);
    };
    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteItem = async () => {
        try {
            let data = {};
            if (typeDelete === '/documents/disable') data.document_id = deleteId;
            if (typeDelete === '/products/disable') data.product_id = deleteId;

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/products/disable') {
                    navigate(`/products/list`);
                } else if (typeDelete === '/documents/disable') {
                    fetchProduct();
                }
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        }
    };

    return (
        <div style={{ padding: 20 }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {productForm.code} - {productForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>
                        {/* Accordion for updating the product */}
                        <Accordion title={`${productForm.code} - ${productForm.name}`}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={productForm.name}
                                            onChange={handleChange}
                                            margin="normal"
                                            inputProps={{ maxLength: 36 }}
                                            InputLabelProps={{ shrink: !!productForm.name }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={types}
                                            getOptionLabel={(option) => option.name?.toString()}
                                            value={selectedType}
                                            onChange={handleTypeChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Tipos"
                                                    style={{ maxWidth: '100%' }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDeleteDialog(id, '/products/disable')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                            Actualizar
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>

                        {/* Accordion for allocations */}
                        <Accordion title="Asignaciones">
                            {/* Estado del producto y botones Asignar/Desasignar */}
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <Box
                                    p={1.5}
                                    border={1}
                                    borderColor={productForm.product_status === 'free' ? '#a5d6a7' : '#ef9a9a'} // Colores pastel
                                    borderRadius={0.5}
                                    bgcolor={productForm.product_status === 'free' ? '#dcedc8' : '#ffccbc'} // Colores pastel
                                    mb={1.5}
                                >
                                    <Typography variant="body1" color="textPrimary">
                                        Estado: {productForm.product_status === 'free' ? 'Libre' : 'Asignado'}
                                    </Typography>
                                </Box>
                                <Box display="flex" gap={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleOpenAssignModal}
                                        disabled={productForm.product_status !== 'free'}
                                    >
                                        Asignar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleUnassignProduct}
                                        disabled={productForm.product_status === 'free'}
                                    >
                                        Desasignar
                                    </Button>
                                </Box>
                            </Box>
                            <br />
                            <br />
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fecha de Inicio</TableCell>
                                            <TableCell>Fecha de Finalización</TableCell>
                                            <TableCell>Trabajador</TableCell>
                                            <TableCell>Obra</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {allocations.length > 0 ? (
                                            allocations.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>
                                                        {formatDate(item.allocation_start_datetime)} {formatTime(item.allocation_start_datetime)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.allocation_finish_datetime ? (
                                                            <>
                                                                {formatDate(item.allocation_finish_datetime)} {formatTime(item.allocation_finish_datetime)}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={`/workers/update/${item.worker.id}`}>
                                                            {item.worker.name || '-'}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={`/buildings/update/${item.building.id}`}>
                                                            {item.building.code  || '-'}
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    No hay asignaciones disponibles.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Accordion for documents */}
                        <Accordion title="Documentos">
                            {/* Botón para subir un documento */}
                            <UploadDocument
                                to={'product_id'}
                                id={id}
                                fetch={fetchProduct} />
                            {/* Listado de documentos */}
                            <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {documents.length > 0 ? (
                                            documents.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>
                                                        <Button onClick={() => handleDownloadDocument(item.id, item.name)}>
                                                            {item.name}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={() => handleOpenDeleteDialog(item.id, '/documents/disable')}>
                                                            Eliminar
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={2} align="center">
                                                    No hay documentos disponibles.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>
                    </Grid>
                    <Modal
                        open={openAssignModal}
                        onClose={handleCloseAssignModal}
                        aria-labelledby="asignar-modal-title"
                        aria-describedby="asignar-modal-description"
                    >
                        <Paper sx={{
                            position: 'absolute',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <Typography id="asignar-modal-title" variant="h6" component="h2" gutterBottom>
                                Asignar trabajador
                            </Typography>
                            <Autocomplete
                                options={workers}
                                getOptionLabel={(option) => option.name?.toString()}
                                value={selectedWorkerForAssignment}
                                onChange={handleWorkerForAssignmentChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecciona un trabajador"
                                        style={{ marginBottom: 10 }}
                                    />
                                )}
                            />
                            {modalError && (
                                <Typography variant="body2" color="error" style={{ marginBottom: 10 }}>
                                    {modalError}
                                </Typography>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                                <Button onClick={handleCloseAssignModal} color="primary" variant="text" sx={{ marginRight: 2 }}>
                                    Cancelar
                                </Button>
                                <Button onClick={handleAssignWorker} color="primary" variant="contained">
                                    Asignar
                                </Button>
                            </div>
                        </Paper>
                    </Modal>
                    {/* Dialog for delete confirmation */}
                    <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                        <DialogTitle>¿Estás seguro?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteDialog} color="primary">
                                Volver
                            </Button>
                            <Button onClick={handleDeleteItem} color="error">
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>)
            }
        </div >
    );
}

export default EditProduct;
