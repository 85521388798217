import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    CircularProgress,
    Typography,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TextField,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TableContainer,
    Table,
    Paper,
    Alert, // Importamos el componente de alerta
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UploadDocument from '../../components/UploadDocument';
import { formatDate, formatPrice } from '../../components/Utils';

dayjs.extend(utc);

function EditDeliveryNote() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const [error, setError] = useState(null);

    const [openInvoice, setOpenInvoice] = useState(false);
    const [retention, setRetention] = useState(null);
    const [retentionDate, setRetentionDate] = useState(null);
    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [vat, setVat] = useState(null);

    const [deliveryNoteData, setDeliveryNoteData] = useState(null);

    const [retentionError, setRetentionError] = useState('');
    const [vatError, setVatError] = useState('');

    const fetchDeliveryNote = useCallback(async () => {
        try {
            const result = await api().get(`/delivery_notes/${id}`);
            setDeliveryNoteData(result.data);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo albarán");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchDeliveryNote();
    }, []);

    const handleCreateInvoice = async () => {
        try {
            const result = await api().post(`/invoices/new`,
                { delivery_notes: [id], retention: retention, vat: vat, invoice_limit_date: invoiceLimitDate, retention_limit_date: retentionDate });
            if (result.status < 300) {
                alert('Factura creada correctamente');
                fetchDeliveryNote();
                handleCloseInvoice(false);
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(error.message);
        }
    };
    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        if (name === 'retention_limit_date') {
            setRetentionDate(date);
        } else if (name === 'invoice_limit_date') {
            setInvoiceLimitDate(date);
        }
    };

    const handleChangeRetention = (event) => {
        const { value } = event.target;

        setRetention(value);

        const retentionValue = parseFloat(value);

        if (isNaN(retentionValue) || retentionValue < 0 || retentionValue > 100 || !Number.isInteger(retentionValue)) {
            setRetentionError('La retención debe ser un número entero entre 0 y 100');
        } else {
            setRetentionError('');
        }
    };

    const handleChangeVat = (event) => {
        const { value } = event.target;

        setVat(value);

        const vatValue = parseFloat(value);

        if (isNaN(vatValue) || vatValue < 0 || vatValue > 100 || !Number.isInteger(vatValue)) {
            setVatError('El IVA debe ser un número entero entre 0 y 100');
        } else {
            setVatError('');
        }
    };

    const handleCloseInvoice = () => {
        setRetention(null);
        setRetentionDate(null);
        setInvoiceLimitDate(null);
        setOpenInvoice(false);
    };

    const handleClickOpenInvoice = () => {
        setOpenInvoice(true);
    };

    const invoiceData = deliveryNoteData?.invoices?.[0];
    const invoiceBase = invoiceData ? invoiceData.base_price || 0 : 0;
    const retentionAmount = invoiceData ? invoiceData.retention || 0 : 0;
    const total = (invoiceData?.total_price || 0) - retentionAmount;
    const vatAmount = (invoiceData?.total_price || 0) - invoiceBase;

    const isButtonDisabled = retentionError || vatError || !retention || !retentionDate || !vat || !invoiceLimitDate;

    const deliveryNoteTotal = deliveryNoteData?.delivery_note_product_orders.reduce((acc, order) => {
        const totalPrice = order.amount * order.product_order.price;
        return acc + totalPrice;
    }, 0);

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    {/* Mostrar el mensaje de error si existe */}
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '20px' }}>
                            {error}
                        </Alert>
                    )}

                    <Card sx={{ margin: 'auto' }}>
                        <CardHeader
                            title={
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                    <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                        {`Albarán: ${deliveryNoteData.code} | Fecha: ${formatDate(deliveryNoteData.dn_date) || '-'}`}
                                    </span>
                                </div>
                            }
                            sx={{
                                backgroundColor: '#f5f5f5',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                padding: '16px 0'
                            }}
                        />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Información del Proveedor */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información del Proveedor
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                            <strong>Código: </strong>
                                            <Link to={`/providers/update/${deliveryNoteData.provider.id}`}>
                                                {deliveryNoteData.provider.code || '-'} <br />
                                            </Link>
                                            <strong>Nombre: </strong> {deliveryNoteData.provider.name || '-'} <br />
                                            <strong>Email: </strong> {deliveryNoteData.provider.email || '-'} <br />
                                            <strong>Teléfono: </strong> {deliveryNoteData.provider.telephone_number || '-'} <br />
                                            <strong>CIF: </strong> {deliveryNoteData.provider.cif || '-'} <br />
                                        </Typography>
                                    </Paper>
                                </Grid>

                                {/* Información de la Factura */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                            Información de la Factura
                                        </Typography>
                                        {invoiceData ? (
                                            <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                                <strong>Código: </strong>
                                                <Link to={`/invoices/update/${invoiceData.id}`}>
                                                    {invoiceData.code} <br />
                                                </Link>
                                                <strong>Fecha de la Factura: </strong> {formatDate(invoiceData.invoice_date) || '-'} <br />
                                                <strong>Base imponible: </strong> {formatPrice(invoiceBase)} <br />
                                                <strong>IVA: </strong> {invoiceData.vat}% {formatPrice(vatAmount)} <br />
                                                <strong>Retención: </strong> {formatPrice(retentionAmount)} <br />
                                                <strong>Total: </strong> {formatPrice(total)} <br />
                                            </Typography>
                                        ) : (
                                            <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={handleClickOpenInvoice}>
                                                Facturar
                                            </Button>
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>

                            {/* Tabla de productos del Albarán */}
                            <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                    Obra:
                                    <Link to={`/buildings/update/${deliveryNoteData.building.id}`}>
                                        {deliveryNoteData.building.code || '-'}
                                    </Link> - {deliveryNoteData.building.name || '-'}
                                </Typography>

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Producto</strong></TableCell>
                                                <TableCell><strong>Cantidad</strong></TableCell>
                                                <TableCell><strong>Precio Unitario</strong></TableCell>
                                                <TableCell><strong>Total</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deliveryNoteData.delivery_note_product_orders.map((order) => {
                                                const product = order.product_order;
                                                const totalPrice = order.amount * product.price;

                                                return (
                                                    <TableRow key={order.id}>
                                                        <TableCell>{product.product_name || '-'}</TableCell>
                                                        <TableCell>{order.amount} {product.unit}</TableCell>
                                                        <TableCell>{formatPrice(product.price)}</TableCell>
                                                        <TableCell>{formatPrice(totalPrice)}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {/* Información adicional del total */}
                                            <TableRow>
                                                <TableCell rowSpan={3} colSpan={2}></TableCell>
                                                <TableCell><strong>Base imponible</strong></TableCell>
                                                <TableCell>{formatPrice(deliveryNoteTotal)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell><strong>Total</strong></TableCell>
                                                <TableCell><strong>{formatPrice(deliveryNoteTotal)}</strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                            {/* Espacio y alineación de UploadDocument */}
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {deliveryNoteData.documents && deliveryNoteData.documents.length > 0 && (
                                    <Button
                                        onClick={() =>
                                            handleDownloadDocument(deliveryNoteData.document[0].id, deliveryNoteData.document[0].name)
                                        }
                                        sx={{ marginRight: 2 }}
                                    >
                                        {deliveryNoteData.documents[0].name}
                                    </Button>
                                )}
                                <UploadDocument
                                    to={'delivery_note_id'}
                                    id={id}
                                    fetch={fetchDeliveryNote}
                                >
                                </UploadDocument>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Diálogo de creación de factura */}
                    <Dialog open={openInvoice} onClose={handleCloseInvoice}>
                        <DialogTitle>Crear Factura</DialogTitle>
                        <DialogContent>
                            <br />
                            <Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <MobileDatePicker
                                        label="Fecha vencimiento factura"
                                        closeOnSelect={true}
                                        onChange={(newValue) => handleDateChange('invoice_limit_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid>
                                <TextField
                                    name="vat"
                                    label="IVA (%)"
                                    fullWidth
                                    value={vat}
                                    onChange={handleChangeVat}
                                    margin="normal"
                                    type="number"
                                    error={!!vatError}
                                    helperText={vatError}

                                />
                            </Grid>
                            <Grid>
                                <TextField
                                    name="retention"
                                    label="Retención (%)"
                                    fullWidth
                                    value={retention}
                                    onChange={handleChangeRetention}
                                    margin="normal"
                                    type="number"
                                    error={!!retentionError}
                                    helperText={retentionError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <MobileDatePicker
                                        label="Fecha vencimiento retención"
                                        closeOnSelect={true}
                                        onChange={(newValue) => handleDateChange('retention_limit_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseInvoice} color="secondary">
                                Cancelar
                            </Button>
                            <Button onClick={handleCreateInvoice} disabled={isButtonDisabled} color="primary">
                                Crear factura
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )}
        </div>
    );
}

export default EditDeliveryNote;
