import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress,
    Typography
} from "@mui/material";
import { itemsPerPage } from '../../components/Utils';

function ListWorkers() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [buildings, setBuildings] = useState([]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;
            params.disabled = false;

            const result = await api().get("/buildings/", { params });

            setBuildings(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
            setLoading(false);
        }
    }, [api, currentPage]);

    useEffect(() => {
        fetchBuildings();
    }, [currentPage]);

    return (
        <div>
            <br />
            <Typography variant="h4" align="center" gutterBottom>
                Seleccione una obra
            </Typography>
            <br />
            <TableContainer component={Paper} sx={{ maxWidth: 600, margin: 'auto' }}>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                )}
                {!loading && buildings && buildings.length > 0 && (
                    <Table aria-label="simple table" sx={{ minWidth: 300 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '70%' }}>
                                    <Typography variant="h6" gutterBottom>Nombre</Typography>
                                </TableCell>
                                <TableCell sx={{ width: '30%' }}>
                                    <Typography variant="h6" gutterBottom>Fichaje</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buildings.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row" sx={{ width: '70%' }}>
                                        {item.name}
                                    </TableCell>
                                    <TableCell sx={{ width: '30%' }}>
                                        <Link to={`/clock_ins/${item.id}`}>
                                            <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                                Fichar
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!loading && buildings && buildings.length === 0 && (
                    <Typography variant="h6" align="center" gutterBottom>
                        Todavía no hay obras.
                    </Typography>
                )}
            </TableContainer>
            <br />
            <br />
            <br />
            {!loading && buildings && buildings.length > 0 && (
                <Paginate
                    totalItems={totalItems}
                    onPageChange={setCurrentPage}
                    currentPage={currentPage}
                />
            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default ListWorkers;
