import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TableContainer, TableHead, TableRow, TextField, Button, Grid, CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import { useAuth } from "../pages/login/OAuth";
import CreatePdf from "./CreatePdf";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatDate } from "./Utils"

dayjs.extend(utc);

const CertificationTable = ({
    showBuilding,
    showCreateCertification,
    buildingItems,
    buildingId,
    certifications,
    fetch,
    handleOpenDeleteDialog
}) => {
    const [loading, setLoading] = useState(false);
    const { api } = useAuth();

    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [selectedCert, setSelectedCert] = useState(null);
    const [formValues, setFormValues] = useState({
        retention: 0,
        vat: 0,
        retentionDate: null,
        invoiceLimitDate: null,
    });
    const [errors, setErrors] = useState({
        vat: '',
        retention: '',
    });

    const [openCertModal, setOpenCertModal] = useState(false);
    const [certificationForm, setCertificationForm] = useState({});
    const [certificationsError, setCertificationsError] = useState(null);

    // Helper function for resetting form and modal states
    const resetFormAndClose = (setModalState, resetForm) => {
        resetForm();
        setModalState(false);
    };

    // Handlers for opening/closing modals
    const handleClickOpenInvoice = (cert) => {
        setSelectedCert(cert);
        setOpenInvoiceModal(true);
    };

    const handleCloseInvoice = () => {
        resetFormAndClose(setOpenInvoiceModal, () => setFormValues({ retention: 0, vat: 0, retentionDate: null, invoiceLimitDate: null, }));
    };

    const handleCloseCert = () => {
        resetFormAndClose(setOpenCertModal, () => setCertificationForm({}));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));

        const numericValue = Number(value);
        // Verificar que el valor es un número entero y está en el rango permitido
        if (!isNaN(numericValue) && Number.isInteger(numericValue) && numericValue >= 0 && numericValue <= 100) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [name]: 'El valor debe ser un número entero entre 0 y 100' }));
        }
    };

    const handleRetentionDateChange = (newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        setFormValues(prevValues => ({ ...prevValues, retentionDate: date }));
    };
    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');

        if (name === 'retention_limit_date') {
            setFormValues(prevValues => ({ ...prevValues, retentionDate: date }));
        } else if (name === 'invoice_limit_date') {
            setFormValues(prevValues => ({ ...prevValues, invoiceLimitDate: date }));
        }
    };

    // Invoice creation
    const handleCreateInvoice = async () => {
        setLoading(true);
        try {
            const { vat, retention, incoiceLimitDate, retentionDate } = formValues;
            const result = await api().post('/invoices/new', {
                certification: selectedCert.id,
                vat,
                retention,
                retention_limit_date: retentionDate,
                invoice_limit_date: incoiceLimitDate,
            });

            if (result.status < 300) {
                alert('Factura creada correctamente');
                handleCloseInvoice();
                fetch();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Certification form change handlers
    const handleCreateCertification = async () => {
        setLoading(true);
        setCertificationsError(null);
        try {
            const filteredCertificationForm = {
                ...certificationForm,
                items: certificationForm.items.filter(item => item.percentage)
            };
            const result = await api().post('/certifications/new', filteredCertificationForm);
            if (result.status < 300) {
                alert('Certificación creada correctamente');
                handleCloseCert();
                fetch();
            } else {
                setCertificationsError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setCertificationsError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCertificationDateChange = (newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        setCertificationForm(prevForm => ({ ...prevForm, certification_date: date }));
    };

    const handleInputCertificationChange = (itemId, value) => {
        setCertificationForm(prevForm => ({
            ...prevForm,
            items: prevForm.items.map(item => item.item_id === itemId ? { ...item, percentage: value } : item)
        }));
    };

    const handleClickOpenCert = () => {
        setOpenCertModal(true);
        setCertificationForm({
            building_id: buildingId,
            description: null,
            certification_date: null,
            items: buildingItems.map(item => ({ item_id: item.id, percentage: null })),
        });
    };

    return (
        <Accordion title="Certificaciones">
            {showCreateCertification && (
                <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleClickOpenCert}>
                    Crear certificación
                </Button>
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Código</TableCell>
                            <TableCell>Facturada</TableCell>
                            <TableCell>Precio</TableCell>
                            <TableCell>Fecha</TableCell>
                            {showBuilding && <TableCell>Obra</TableCell>}
                            <TableCell>Partes de la obra</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {certifications.filter(item => !item.disabled).map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Link to={`/certifications/update/${item.id}`}>
                                        {item.code || '-'}
                                    </Link>
                                </TableCell>
                                <TableCell>{item.invoiced ? "Sí" : "No"}</TableCell>
                                <TableCell>{item.price?.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}</TableCell>
                                <TableCell>{formatDate(item.certification_date) || '-'}</TableCell>
                                {showBuilding && (
                                    <TableCell>
                                        <Link to={`/buildings/update/${item.building.id}`}>
                                            {item.building.name || '-'}
                                        </Link>
                                    </TableCell>
                                )}
                                <TableCell>
                                    {item.item_progress.map((product, idx) => (
                                        <div key={idx}>{product.item.name} - {product.percentage}%</div>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" sx={{ mr: 1 }} color="primary" disabled={item.invoiced} onClick={() => handleClickOpenInvoice(item)}>
                                        Crear factura
                                    </Button>
                                    <CreatePdf endpoint={`/certifications/${item.id}/generate-pdf`} />
                                    <Button variant="contained" color="secondary" onClick={() => handleOpenDeleteDialog(item.id, '/certifications/disable')}>
                                        Borrar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Invoice Dialog */}
            {selectedCert && (
                <Dialog open={openInvoiceModal} onClose={handleCloseInvoice}>
                    <DialogTitle>Crear Factura</DialogTitle>
                    <DialogContent>
                        <br />
                        <Grid>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                                localeText={{
                                    cancelButtonLabel: "cancelar",
                                    okButtonLabel: "Ok",
                                    datePickerToolbarTitle: 'Seleccionar',
                                }}>
                                <MobileDatePicker
                                    label="Fecha vencimiento factura"
                                    closeOnSelect={true}
                                    onChange={(newValue) => handleDateChange('invoice_limit_date', newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid>
                            <TextField
                                name="vat"
                                label="IVA (%)"
                                fullWidth
                                value={formValues.vat || ''}
                                onChange={handleChange}
                                margin="normal"
                                type="number"
                                error={!!errors.vat}
                                helperText={errors.vat} />
                        </Grid>
                        <Grid>
                            <TextField
                                name="retention"
                                label="Retención (%)"
                                fullWidth
                                value={formValues.retention || ''}
                                onChange={handleChange}
                                margin="normal"
                                type="number"
                                error={!!errors.retention}
                                helperText={errors.retention} />
                        </Grid>
                        <Grid>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="es"
                                localeText={{
                                    cancelButtonLabel: "cancelar",
                                    okButtonLabel: "Ok",
                                    datePickerToolbarTitle: 'Seleccionar',
                                }}>
                                <MobileDatePicker
                                    label="Fecha máxima retención"
                                    closeOnSelect
                                    onChange={handleRetentionDateChange}
                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                        margin="normal" />} />
                            </LocalizationProvider>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseInvoice}>Cancelar</Button>
                        <Button onClick={handleCreateInvoice} disabled={!!errors.vat || !!errors.retention || !formValues.retention || !formValues.retentionDate || loading}>
                            {loading ? <CircularProgress size={24} /> : 'Crear Factura'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Certification Dialog */}
            {buildingItems && buildingItems.length > 0 && (
                <Dialog open={openCertModal} onClose={handleCloseCert}>
                    <DialogTitle>Crear Certificación</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Descripción"
                            name="description"
                            value={certificationForm.description}
                            onChange={(e) => setCertificationForm({ ...certificationForm, description: e.target.value })}
                            margin="normal" />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                            <MobileDatePicker
                                label="Fecha"
                                value={certificationForm.certification_date ? dayjs(certificationForm.certification_date) : null}
                                onChange={handleCertificationDateChange}
                                renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                        <br /><br />
                        {buildingItems.filter(item => !item.disabled).map((item) => (
                            <Grid container spacing={2} key={item.id} alignItems="center">
                                <Grid item xs={6}>{item.name}</Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense" label="Porcentaje progreso" type="number" inputProps={{ min: 0, max: 100 }} fullWidth
                                        value={certificationForm.items?.find(certItem => certItem.item_id === item.id)?.percentage || ''}
                                        onChange={(e) => handleInputCertificationChange(item.id, e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCert}>Cancelar</Button>
                        <Button onClick={handleCreateCertification} disabled={!certificationForm.items?.some(item => item.percentage > 0)}>
                            Crear
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Accordion>
    );
};

export default CertificationTable;
