import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import {
    Typography,
    Paper,
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    Autocomplete,
    TableRow,
    TableCell,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    FormHelperText,
    DialogContent,
    Box,
    DialogTitle,
    TextField,
    Checkbox
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Accordion from '../../components/Accordion';
import InvoiceRetentionTable from '../../components/InvoiceRetentionTable';
import UploadDocument from '../../components/UploadDocument';
import CreatePdf from '../../components/CreatePdf';
import { formatDate } from '../../components/Utils';

dayjs.extend(utc);

const EditOrder = () => {
    const { id } = useParams();
    const { api } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [order, setOrder] = useState(null);
    const [openInvoice, setOpenInvoice] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [quantityErrors, setQuantityErrors] = useState({});
    const [inputErrors, setInputErrors] = useState({});

    const [selectedDNs, setSelectedDNs] = useState([]);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [typeDelete, setTypeDelete] = useState(false);

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);
    const [totalRetention, setTotalRetention] = useState(null);
    const [paidRetention, setPaidRetention] = useState(null);
    const [remainingRetentionSum, setRemainingRetentionSum] = useState(null);

    const [deliveryNotes, setDeliveryNotes] = useState([]);

    const [retention, setRetention] = useState(null);
    const [retentionDate, setRetentionDate] = useState(null);
    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [vat, setVat] = useState(null);

    const [errors, setErrors] = useState({
        vat: '',
        retention: '',
    });

    const fetchOrder = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api().get(`/orders/${id}`);
            const order = response.data.order
            setOrder(order);
            setDeliveryNotes(response.data.delivery_notes);
            setInvoices(response.data.invoices);
            setTotalPriceSum(response.data.total_price_sum);
            setPaidAmountSum(response.data.paid_amount_sum);
            setRemainingAmountSum(response.data.remaining_amount_sum);
            setTotalRetention(response.data.total_retentions);
            setPaidRetention(response.data.paid_retention);
            setRemainingRetentionSum(response.data.remaining_retention_sum);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }, [api, id]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api().get(`/buildings/`);
            setBuildings(response.data[0]);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        fetchOrder();
        fetchBuildings();
    }, [id]);

    const handleClickOpenInvoice = () => {
        setSelectedDNs([]);
        setOpenInvoice(true);
    };

    const handleCloseInvoice = () => {
        setRetention(null);
        setRetentionDate(null);
        setVat(null);
        setInvoiceLimitDate(null);
        setOpenInvoice(false);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'retention') {
            setRetention(value)

        } else if (name === 'vat') {
            setVat(value)
        }
        const numericValue = Number(value);
        // Verificar que el valor es un número entero y está en el rango permitido
        if (!isNaN(numericValue) && Number.isInteger(numericValue) && numericValue >= 0 && numericValue <= 100) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [name]: 'El valor debe ser un número entero entre 0 y 100' }));
        }
    };

    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        if (name === 'retention_limit_date') {
            setRetentionDate(date);
        } else if (name === 'invoice_limit_date') {
            setInvoiceLimitDate(date);
        }
    };
    const handleCreate = async () => {
        const delNoteData = {
            order_id: id,
            products: Object.entries(quantities).map(([productId, quantity]) => ({
                product_id: productId,
                quantity: parseFloat(quantity)
            }))
        };
        try {
            const result = await api().post(`/orders/dn_order`, delNoteData);
            if (result.status < 300) {
                alert('Albarán creado correctamente');
                fetchOrder();
                setQuantities({});
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleCreateInvoice = async () => {
        try {
            const result = await api().post(`/invoices/new`,
                { delivery_notes: selectedDNs, retention: retention, vat: vat, invoice_limit_date: invoiceLimitDate, retention_limit_date: retentionDate });
            if (result.status < 300) {
                alert('Factura creada correctamente');
                fetchOrder();
                handleCloseInvoice(false);
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleQuantityChange = (id, value) => {
        const quantity = parseFloat(value);
        const orderedQuantity = order.product_orders.find(item => item.id === id).quantity;
        const receivedQuantity = order.product_orders.find(item => item.id === id).received_quantity || 0;

        let error = false;
        let inputError = false; // New input error flag

        if (isNaN(quantity) || quantity < 0 || quantity > orderedQuantity) {
            error = true;
            inputError = isNaN(quantity); // Set input error if value is not a number
        } else if (receivedQuantity + quantity > orderedQuantity) {
            error = true;
        }

        setQuantityErrors(prevErrors => ({
            ...prevErrors,
            [id]: error
        }));

        setInputErrors(prevErrors => ({
            ...prevErrors,
            [id]: inputError
        }));

        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [id]: quantity
        }));
    };

    const handleDNChange = (id) => {
        setSelectedDNs(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(albaranId => albaranId !== id)
                : [...prevSelected, id]
        );
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (!order) {
        return <Typography variant="body1">Pedido no encontrado</Typography>;
    }


    const handleBuildingChange = (event, newValue) => {
        setSelectedBuilding(newValue);
    };


    const changeBuilding = async () => {
        try {
            setLoading(true);
            const result = await api().patch(`/orders/add_building`, { order_id: id, building_id: selectedBuilding.id });
            if (result.status < 300) {
                alert('Obra añadida correctamente');
                fetchOrder();
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };
    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };
    const handleDeleteItem = async () => {
        try {
            let data = {}
            if (typeDelete === '/orders/disable_dn') data.delivery_note_id = deleteId
            if (typeDelete === '/orders/disable') data.order_id = deleteId
            if (typeDelete === '/invoices/disable') data.invoice_id = deleteId

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/orders/disable') {
                    navigate(`/orders/list`);
                }
                fetchOrder();
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        };
    }

    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };

    const hasErrors = Object.values(quantityErrors).some(error => error);
    const allQuantitiesFilled = Object.values(quantities).some(qty => qty !== 0 && qty !== '');
    const hasInputErrors = Object.values(inputErrors).some(error => error);
    const hasUninvoicedDNs = deliveryNotes.some(dn => !dn.invoiced);

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                <Grid item>
                    <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                        <Box mb={2}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                {order.code}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            <Accordion title={`Detalles del pedido ${order.code} `}>
                <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>FECHA DEL PEDIDO:</Typography>
                        <Typography variant="body1">
                            {formatDate(order.order_date) || '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>ESTADO DEL PEDIDO:</Typography>
                        <Typography variant="body1">
                            {order.order_status === 'partly_received' ? 'PARCIALMENTE ACEPTADO' :
                                order.order_status === 'received' ? 'RECIBIDO' :
                                    order.order_status === 'pending' ? 'PENDIENTE' :
                                        order.order_status || '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>VER PEDIDO:</Typography>
                        <CreatePdf endpoint={`/orders/${id}/generate-pdf`}></CreatePdf>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>BORRAR EL PEDIDO:</Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleOpenDeleteDialog(order.id, '/orders/disable')}
                        >
                            Borrar
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>IMPORTE TOTAL:</Typography>
                        <Typography variant="body1" >
                            {order.total_price ? order.total_price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>PROVEEDOR:</Typography>
                        <Typography variant="body1">
                            <Link to={`/providers/update/${order.provider.id}`} >
                                {order.provider.code || '-'}
                            </Link>
                        </Typography>
                        <Typography variant="body1">{order.provider.name || '-'}</Typography>
                        <Typography variant="body1">{order.provider.telephone_number || '-'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>OBRA:</Typography>
                        {order.building && order.building.code && order.building.name ? (
                            <>
                                <Typography variant="body1">
                                    <Link to={`/buildings/update/${order.building.id}`}>
                                        {order.building.code || '-'}
                                    </Link>
                                </Typography>
                                <Typography variant="body1">{order.building.name || '-'}</Typography>
                            </>
                        ) : (
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={9} md={5} mt={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.code?.toString() + ' - ' + option.name?.toString()}
                                        value={selectedBuilding}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Seleccionar obra"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3} md={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={changeBuilding}
                                        fullWidth
                                    >
                                        Cambiar
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                </Grid>


                <Typography variant="h5" gutterBottom>
                    Productos del pedido
                </Typography>
                <Button variant="contained" color="primary" disabled={hasErrors || !allQuantitiesFilled || hasInputErrors} onClick={handleCreate} style={{ marginTop: 20 }}>
                    Crear albarán
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre del Producto</TableCell>
                                <TableCell>Importe</TableCell>
                                <TableCell>Precio individual</TableCell>
                                <TableCell>Cantidad pedida</TableCell>
                                <TableCell>Cantidad recibida</TableCell>
                                <TableCell>Cantidad a recibir</TableCell>
                                <TableCell>Estado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.product_orders.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.product_name}</TableCell>
                                    <TableCell>
                                        {item.price && item.quantity ? (item.price * item.quantity).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' : '0,00€'}
                                    </TableCell>
                                    <TableCell>
                                        {item.price ? item.price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €' : '0,00€'}
                                    </TableCell>
                                    <TableCell>{item.quantity ? item.quantity.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00€'} {item.unit === 'l' ? 'LITROS' :
                                        item.unit === 'kg' ? 'KGS' :
                                            item.unit === 'uds' ? 'UNIDADES' :
                                                item.unit}
                                    </TableCell>
                                    <TableCell>{item.received_quantity ? item.received_quantity.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00€'} {item.unit === 'l' ? 'LITROS' :
                                        item.unit === 'kg' ? 'KGS' :
                                            item.unit === 'uds' ? 'UNIDADES' :
                                                item.unit}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={quantities[item.id] || ''}
                                            onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                                            inputProps={{ min: 0, max: item.quantity }}
                                            fullWidth
                                            disabled={item.received_quantity === item.quantity}
                                            error={quantityErrors[item.id] || inputErrors[item.id] || false} // Add input error check
                                        />
                                        {(quantityErrors[item.id] || inputErrors[item.id]) && (
                                            <FormHelperText error>
                                                Valor no válido
                                            </FormHelperText>
                                        )}

                                    </TableCell>
                                    <TableCell>
                                        {item.product_order_status === 'received' ? 'RECIBIDO' :
                                            item.product_order_status === 'pending' ? 'PENDIENTE' :
                                                item.product_order_status === 'partly_received' ? 'PARCIALMENTE RECIBIDO' :
                                                    item.product_order_status || '-'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )}

            </Accordion>
            <Accordion title='Albaranes'>
                <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={handleClickOpenInvoice} disabled={!hasUninvoicedDNs}>
                    Crear factura
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Código</TableCell>
                                <TableCell>Facturado</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deliveryNotes.filter(item => !item.disabled).map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <Link to={`/delivery_notes/update/${item.id}`}>
                                            {item.code || '-'}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{item.invoiced ? "Sí" : "No"}</TableCell>
                                    <TableCell>{formatDate(item.dn_date)}</TableCell>
                                    <TableCell>
                                        {item.documents && item.documents.length > 0 && (
                                            <Button
                                                onClick={() =>
                                                    handleDownloadDocument(item.documents[0].id, item.documents[0].name)
                                                }
                                                sx={{ marginRight: 2 }}
                                            >
                                                {item.documents[0].name}
                                            </Button>
                                        )}
                                        <UploadDocument
                                            to={'delivery_note_id'}
                                            id={item.id}
                                            fetch={fetchOrder}
                                        >
                                        </UploadDocument>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDeleteDialog(item.id, '/orders/disable_dn')}
                                            sx={{ ml: 2 }}
                                        >
                                            Borrar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Accordion>

            <InvoiceRetentionTable
                isInvoice={true}
                isClient={true}
                showBuilding={false}
                showClientOrProvider={false}
                title='Facturas'
                totalAmount={totalPriceSum}
                paidAmount={paidAmountSum}
                remainingAmountSum={remainingAmountSum}
                invoices={invoices}
                uploadDocument={true}
                fetch={fetchOrder}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
            />
            <InvoiceRetentionTable
                isInvoice={false}
                isClient={true}
                showBuilding={false}
                showClientOrProvider={false}
                title='Retenciones'
                totalAmount={totalRetention}
                paidAmount={paidRetention}
                remainingAmountSum={remainingRetentionSum}
                invoices={invoices}
                fetch={fetchOrder}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
            />

            <Dialog open={openInvoice} onClose={handleCloseInvoice}>
                <DialogTitle>Crear Factura</DialogTitle>
                <DialogContent>
                    <br />
                    <Grid>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                            localeText={{
                                cancelButtonLabel: "cancelar",
                                okButtonLabel: "Ok",
                                datePickerToolbarTitle: 'Seleccionar',
                            }}>
                            <MobileDatePicker
                                label="Fecha vencimiento factura"
                                closeOnSelect={true}
                                onChange={(newValue) => handleDateChange('invoice_limit_date', newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid >
                        <TextField
                            name="vat"
                            label="IVA (%)"
                            fullWidth
                            value={vat}
                            onChange={handleChange}
                            margin="normal"
                            type="number"
                            error={!!errors.vat}
                            helperText={errors.vat}
                        />
                    </Grid>
                    <Grid>
                        <TextField
                            name="retention"
                            label="Retención (porcentaje)"
                            fullWidth
                            value={retention}
                            onChange={handleChange}
                            margin="normal"
                            type="number"
                            error={!!errors.retention}
                            helperText={errors.retention} />
                    </Grid>
                    <Grid >
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="es"
                            localeText={{
                                cancelButtonLabel: "cancelar",
                                okButtonLabel: "Ok",
                                datePickerToolbarTitle: 'Seleccionar',
                            }}>
                            <MobileDatePicker
                                label="Fecha vencimiento retención"
                                closeOnSelect={true}
                                onChange={(newValue) => handleDateChange('retention_limit_date', newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Seleccionar</TableCell>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Fecha</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveryNotes.filter(item => !item.invoiced).map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedDNs.includes(item.id)}
                                                onChange={() => handleDNChange(item.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{item.code}</TableCell>
                                        <TableCell>{item.dn_date}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseInvoice} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={handleCreateInvoice} disabled={!!errors.vat || !!errors.retention || !retention || !retentionDate || !vat || selectedDNs.length <= 0} color="primary">
                        Crear factura
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>¿Estás seguro?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Volver
                    </Button>
                    <Button onClick={handleDeleteItem} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditOrder;
