import React, { useState, useEffect, useCallback } from "react";
import Paginate from '../../components/Paginate';
import { Link } from 'react-router-dom';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { useAuth } from "../login/OAuth";
import "dayjs/locale/es";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress,
    Typography,
    Grid,
    TextField,
    Autocomplete
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { itemsPerPage, formatDate, formatTime } from '../../components/Utils';

dayjs.extend(utc);

function ListClockIns() {
    const { api } = useAuth();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchReset, setFetchReset] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [clockIns, setClockIns] = useState([]);

    const [buildings, setBuildings] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState(null);

    const [workers, setWorkers] = useState([]);
    const [workerSelected, setWorkerSelected] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const fetchClockIns = useCallback(async () => {
        try {
            setLoading(true);
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;
            if (buildingSelected) params.building_id = buildingSelected.id;
            if (workerSelected) params.worker_id = workerSelected.id;
            if (sortColumn) params.sort_by = sortColumn;
            if (sortDirection) params.sort_direction = sortDirection;
            if (startDate) {
                const startDateMidday = dayjs(startDate).hour(12).minute(0).second(0);
                params.start_date = startDateMidday.utc().format('YYYY-MM-DD');
            }
            if (endDate) {
                const endDateMidday = dayjs(endDate).hour(12).minute(0).second(0);
                params.end_date = endDateMidday.utc().format('YYYY-MM-DD');
            }

            const result = await api().get("/clock_ins/", { params });

            setClockIns(result.data[0]);
            setTotalItems(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api, currentPage, buildingSelected, workerSelected, startDate, endDate, sortColumn, sortDirection]);

    const fetchBuildings = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/buildings/");

            setBuildings(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    const fetchWorkers = useCallback(async () => {
        try {
            setLoading(true);

            const result = await api().get("/workers/");

            setWorkers(result.data[0]);
            setLoading(false);
        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api]);

    useEffect(() => {
        fetchBuildings();
        fetchWorkers();
    }, []);

    useEffect(() => {
        fetchClockIns();
    }, [currentPage, sortDirection, sortColumn]);

    useEffect(() => {
        if (fetchReset) {
            fetchClockIns();
            setFetchReset(false);
        }
    }, [fetchReset]);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const handleWorkerChange = (event, newValue) => {
        setWorkerSelected(newValue);
    };

    const handleBuildingChange = (event, newValue) => {
        setBuildingSelected(newValue);
    };
    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
    };

    const handleFilters = (action) => {
        setCurrentPage(1);

        if (action === "reset") {
            setWorkerSelected(null);
            setBuildingSelected(null);
            setStartDate(null);
            setEndDate(null);
            setFetchReset(true);
        }

        else if (action === "apply") {
            fetchClockIns();
        }
    };

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '75px'
    };

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={workers}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={workerSelected}
                                        onChange={handleWorkerChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Operarios"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={2}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => option.name?.toString()}
                                        value={buildingSelected}
                                        onChange={handleBuildingChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obras"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha de Inicio"
                                            closeOnSelect={true}
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="es"
                                        localeText={{
                                            cancelButtonLabel: "cancelar",
                                            okButtonLabel: "Ok",
                                            datePickerToolbarTitle: 'Seleccionar',
                                        }}>
                                        <MobileDatePicker
                                            label="Fecha de Fin"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            closeOnSelect={true}
                                            renderInput={(params) => <TextField {...params} />}

                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => handleFilters("apply")}>
                                        Aplicar Filtros
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="secondary" onClick={() => handleFilters("reset")}>
                                        Resetear Filtros
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <br />
                    <br />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('building_code')} style={{ cursor: 'pointer' }}>
                                                Obra {sortColumn === 'building_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('worker_name')} style={{ cursor: 'pointer' }}>
                                                Operario {sortColumn === 'worker_name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('clock_in_datetime')} style={{ cursor: 'pointer' }}>
                                                Fecha {sortColumn === 'clock_in_datetime' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="h6" gutterBottom>
                                            <span onClick={() => handleSort('clock_in_datetime')} style={{ cursor: 'pointer' }}>
                                                Hora {sortColumn === 'clock_in_datetime' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clockIns.map(item => (
                                    <TableRow key={item.id}>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/buildings/update/${item.building.id}`}>
                                                {item.building.code || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={cellStyle}>
                                            <Link to={`/workers/update/${item.worker.id}`}>
                                                {item.worker.name || '-'}
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatDate(item.clock_in_datetime) || '-'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {formatTime(item.clock_in_datetime) || '-'}
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
            }
            <br />
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
            {
                error && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error}
                    </Typography>
                )
            }
        </div >
    );
}

export default ListClockIns;
